const linkToDomElements = {
  body: document.querySelector("body"),
  menuButton: document.querySelector("#menu"),
  menuContent: document.querySelector("#hidden-menu-content"),
  closeMenu: document.querySelector("#close-menu-content"),
  questionBlock: document.querySelector("#question-block"),
  linksWithHook: document.querySelectorAll('a[href^="#"]'),
  serviceCards: document.querySelectorAll(".service-card"),
};

const {
  body,
  menuButton,
  menuContent,
  closeMenu,
  questionBlock: { children: questionBlockChildren },
  linksWithHook,
  serviceCards,
} = linkToDomElements;

/* Initial event listeners */
for (let questionItem of questionBlockChildren) {
  questionItem.addEventListener("click", openOrCloseAnswerHandler);
}

linksWithHook.forEach((anchor) => {
  anchor.addEventListener("click", scrollWithHookHandler);
});

// serviceCards.forEach((anchor) => {
//   anchor.addEventListener("mousedown", func);
// });

// function func() {
//   console.log("focus");
// }

menuButton.addEventListener("click", openMenuHandler);

/*
 * Event listener handlers
 */
function openMenuHandler() {
  menuContent.classList.remove("menu-container__panel_hidden");
  body.style.overflow = "hidden";

  closeMenu.addEventListener("click", closeMenuHandler);
}

function closeMenuHandler() {
  menuContent.classList.add("menu-container__panel_hidden");
  body.removeAttribute("style");

  closeMenu.removeEventListener("click", closeMenuHandler);
}

function scrollWithHookHandler(e) {
  e.preventDefault();

  const targetId = this.getAttribute("href");
  const targetElement = document.querySelector(targetId);

  if (targetElement) {
    menuContent.classList.add("menu-container__panel_hidden");
    body.removeAttribute("style");

    targetElement.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }
}

function openOrCloseAnswerHandler({ currentTarget }) {
  const answer = currentTarget.children.item(1);
  const answerButton = currentTarget.children[0].children[1];
  const isAnswerHidden = answer.classList.contains(
    "question-item__answer_hidden",
  );

  if (isAnswerHidden) {
    answer.classList.remove("question-item__answer_hidden");
    answerButton.classList.add("question-item__open-button_opened");
    return;
  }

  if (!isAnswerHidden) {
    answer.classList.add("question-item__answer_hidden");
    answerButton.classList.remove("question-item__open-button_opened");
  }
}
