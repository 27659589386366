import InfiniteMarquee from "vanilla-infinite-marquee";

new InfiniteMarquee({
  element: ".marquee-container",
  speed: 15000,
  smoothEdges: true,
  direction: "left",
  gap: "36px", // this option doesn't work, and it needs to manage manually in css
  duplicateCount: 2,
  mobileSettings: {
    direction: "left",
    speed: 20000,
  },
  on: {
    beforeInit: () => {
      console.log("Not Yet Initialized");
    },

    afterInit: () => {
      console.log("Initialized");
    },
  },
});
